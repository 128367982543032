import classNames from 'classnames';

const VERSION = '10';

export type IconNameType =
  | 'search'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'assistant'
  | 'bin'
  | 'bulb'
  | 'caret-down'
  | 'caret-left'
  | 'caret-right'
  | 'caret-up'
  | 'check'
  | 'check-box'
  | 'chevron-left'
  | 'chevron-right'
  | 'clipboard'
  | 'cloud-up'
  | 'cogwheel'
  | 'comment'
  | 'cross'
  | 'decline'
  | 'detail'
  | 'dots'
  | 'envelope'
  | 'flag-empty'
  | 'flag-full'
  | 'lab'
  | 'learning'
  | 'magnify'
  | 'pen'
  | 'persons'
  | 'pie-1-4'
  | 'pie-3-4'
  | 'plus'
  | 'ai'
  | 'strategy'
  | 'play'
  | 'pause'
  | 'raise-hand'
  | 'shopping-cart'
  | 'thumbs-up'
  | 'agency'
  | 'stop'
  | 'update'
  | 'service'
  | 'target-group'
  | 'slider';

export type IconSizeType = 'medium' | 'large';

interface Properties {
  name: IconNameType | string;
  hidden?: boolean;
  title?: string;
  size?: IconSizeType;
  onClick?: () => void;
}

export const Icon = ({ name, hidden, title, size, onClick }: Properties) => {
  const className = classNames('icon', {
    'icon--hidden': !!hidden,
    icon__medium: size && size == 'medium',
    icon__large: size && size == 'large'
  });

  return (
    <svg onClick={onClick} className={className}>
      {title && <title>{title}</title>}
      <use
        xlinkHref={'/icons/icon-symbol-sprites.svg?v=' + VERSION + '#' + name}
      />
    </svg>
  );
};

export default Icon;
