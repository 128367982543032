import { DownloadableElementType, TypedElementMap } from 'models/ActionsStore';
import { ElementType } from 'models/ApiElementTypeEnum';
import { AssistantType } from 'models/AssistantSessionModel';
import { AttachmentRotationEnum } from 'models/AttachmentsStore';
import {
  RecommendableElementType,
  SearchableElementType
} from 'models/ContentListStore';
import { ProjectAccessLevelEnumModelType } from 'models/ProjectAccessLevelEnumModel';
import QueryString from 'query-string';
import ORG_ACCESS_LEVELS from 'utils/constants/org-access-levels';

import { destroy, get, patch, post } from './BaseApi';

type Id = number;
type UnknownData = unknown;

interface IPrototypeScreenBase {
  organizationId: Id;
  projectId: Id;
  screenId: Id;
  prototypeId?: Id;
  assistantSessionId?: Id;
  screenPatch?: UnknownData;
}

interface IPrototypeScreenSwap extends IPrototypeScreenBase {
  screen2Id: Id;
}

interface IGetAssistantSessions {
  organizationId?: number | string;
  authorId?: number | string;
}

const uri = encodeURIComponent;

const convertBaseName = (name: string, baseName?: string) =>
  !baseName ? name : baseName + '[' + name + ']';

const convertToFormData = (
  form: { [key: string]: any },
  baseName?: string,
  fd: FormData = new FormData()
) => {
  for (const fieldName in form) {
    if (form.hasOwnProperty(fieldName)) {
      const data = form[fieldName];
      if (!data && data !== '' && data !== 0 && typeof data !== 'boolean') {
        continue;
      }

      if (data.file && data.file instanceof File) {
        fd.append(convertBaseName(fieldName, baseName), data.file);
      } else if (typeof data === 'boolean') {
        fd.append(
          convertBaseName(fieldName, baseName),
          data ? 'true' : 'false'
        );
      } else if (typeof data === 'object') {
        convertToFormData(data, convertBaseName(fieldName, baseName), fd);
      } else {
        fd.append(convertBaseName(fieldName, baseName), data);
      }
    }
  }

  return fd;
};

const buildProjectUrl = (organizationId: Id, projectId: Id) =>
  '/organizations/' + uri(organizationId) + '/projects/' + uri(projectId);

export class DisrooptiveApi {
  // == session ==
  login(email: string, password: string, remember = false) {
    return post('/session', {
      email,
      password,
      remember: remember ? true : false
    });
  }

  checkAuthenticated(avoidCaching = true) {
    let param = '';
    if (avoidCaching) {
      // Chrome on Android sometimes fetches a cached version of /session API response
      // when navigating back to the web app.
      // Thus forcing Chrome to send request to server by adding a timestamp.
      param = '?r=' + new Date().getTime();
    }
    return get('/session' + param).catch((error) => {
      if (this.isNotFound(error)) {
        // user is not authenticated
        return Promise.resolve();
      }
      return Promise.reject(error);
    });
  }

  logout() {
    return destroy('/session');
  }

  changeLanguage(lang: string) {
    return post('/session/change_language', {
      lang
    });
  }

  initPasswordReset(email: string) {
    return post('/activation/request_password_reset', {
      email
    });
  }

  verifyPasswordResetToken(token: string) {
    return post('/activation/verify_reset', {
      token
    });
  }

  finishPasswordReset(token: string, password: string) {
    return post('/activation/reset_password', {
      token,
      password
    });
  }

  verifyInvitation(token: string) {
    return post('/activation/verify_invite', {
      token
    });
  }

  join(token: string, password: string, firstName?: string, lastName?: string) {
    return post('/activation/join', {
      token,
      password,
      first_name: firstName || undefined,
      last_name: lastName || undefined
    });
  }

  verifyAddress(token: string) {
    return post('/activation/verify_address', {
      token
    });
  }

  // == users ==
  listUsers() {
    return get('/users');
  }

  getUser(id: Id) {
    return get('/users/' + uri(id));
  }

  getUserElements(
    id: Id,
    elementTypes: SearchableElementType | undefined,
    limit: number,
    page?: number
  ) {
    return get(
      '/users/' +
        uri(id) +
        '/elements?' +
        QueryString.stringify(
          {
            element_types: elementTypes,
            limit,
            page
          },
          {
            arrayFormat: 'bracket'
          }
        )
    );
  }

  createUser(
    user: UnknownData,
    organizationId: Id | null = null,
    organizationAccessLevel: ORG_ACCESS_LEVELS | null = null
  ) {
    return post('/users', {
      user,
      organization_id: organizationId,
      organization_access_level: organizationAccessLevel
    });
  }

  updateUser(id: Id, user: UnknownData, currentPassword?: string) {
    return patch('/users/' + uri(id), {
      user,
      current_password: currentPassword || undefined
      // organization_id,   // disabled for now
      // organization_access_level
    });
  }

  reinviteUser(id: Id) {
    return post('/users/' + uri(id) + '/reinvite', {});
  }

  deleteUser(id: Id) {
    return destroy('/users/' + uri(id));
  }

  // == organization ==
  listOrganizations() {
    return get('/organizations');
  }

  getOrganization(id: Id) {
    return get('/organizations/' + uri(id));
  }

  createOrganization(organization: UnknownData) {
    return post('/organizations', {
      organization
    });
  }

  updateOrganization(organizationId: Id, organization: UnknownData) {
    return patch('/organizations/' + uri(organizationId), {
      organization
    });
  }

  deleteOrganization(id: Id) {
    return destroy('/organizations/' + uri(id));
  }

  // == customers ==
  listCustomers(organizationId: Id) {
    return get(`/organizations/${uri(organizationId)}/customers`);
  }

  createCustomer(organizationId: Id, customer: UnknownData) {
    return post(`/organizations/${uri(organizationId)}/create_customer`, {
      customer
    });
  }

  deleteCustomer(organizationId: Id, customerId: Id) {
    return destroy(
      `/organizations/${uri(organizationId)}/customers/${uri(customerId)}`
    );
  }

  // == organization members ==
  listOrganizationMembers(
    organizationId: Id,
    includeSuperusers = false,
    includeAgencyUser = false
  ) {
    return get(
      '/organizations/' +
        uri(organizationId) +
        `/members?include_superusers=${includeSuperusers}&include_agency_users=${includeAgencyUser}`
    );
  }

  createOrUpdateOrganizationMembership(
    organizationId: Id,
    userId: Id,
    accessLevel: ORG_ACCESS_LEVELS
  ) {
    return post(
      '/organizations/' + uri(organizationId) + '/members/' + uri(userId),
      {
        access_level: accessLevel
      }
    );
  }

  deleteOrganizationMembership(organizationId: Id, userId: Id) {
    return destroy(
      '/organizations: string/' +
        uri(organizationId) +
        '/members/' +
        uri(userId)
    );
  }

  // == projects ==
  listProjects(organizationId: Id) {
    return get('/organizations/' + uri(organizationId) + '/projects');
  }

  getProject(organizationId: Id, projectId: Id) {
    return get(
      '/organizations/' + uri(organizationId) + '/projects/' + uri(projectId)
    );
  }

  getStrategyTypes() {
    return get('/strategy_types');
  }

  createProject(organizationId: Id, project: UnknownData) {
    return post('/organizations/' + uri(organizationId) + '/projects', {
      project
    });
  }

  updateProject(projectId: Id, projectPatch: UnknownData, organizationId: Id) {
    return patch(buildProjectUrl(organizationId, projectId), {
      project: projectPatch
    });
  }

  // == target groups ==
  listOrganizationTargetGroups(organizationId: Id) {
    return get('/organizations/' + uri(organizationId) + '/target_groups');
  }

  createTargetGroup(organizationId: Id, targetGroup: UnknownData) {
    return post(
      '/organizations/' + uri(organizationId) + '/target_groups',
      targetGroup
    );
  }

  updateTargetGroup(
    organizationId: Id,
    targetGroupId: Id,
    targetGroupPatch: UnknownData
  ) {
    return patch(
      '/organizations/' +
        uri(organizationId) +
        '/target_groups/' +
        uri(targetGroupId),
      {
        target_group: targetGroupPatch
      }
    );
  }

  deleteTargetGroup(organizationId: Id, targetGroupId: Id) {
    return destroy(
      '/organizations/' +
        uri(organizationId) +
        '/target_groups/' +
        uri(targetGroupId)
    );
  }

  // == org services ==
  listOrganizationServices(organizationId: Id) {
    return get('/organizations/' + uri(organizationId) + '/services');
  }

  createService(organizationId: Id, service: UnknownData) {
    return post('/organizations/' + uri(organizationId) + '/services', {
      service
    });
  }

  updateService(organizationId: Id, serviceId: Id, servicePatch: UnknownData) {
    return patch(
      '/organizations/' + uri(organizationId) + '/services/' + uri(serviceId),
      {
        service: servicePatch
      }
    );
  }

  deleteService(organizationId: Id, serviceId: Id) {
    return destroy(
      '/organizations/' + uri(organizationId) + '/services/' + uri(serviceId)
    );
  }

  deleteProject(organizationId: Id, projectId: Id) {
    return destroy(buildProjectUrl(organizationId, projectId));
  }

  listProjectMembers(organizationId: Id, projectId: Id) {
    return get(buildProjectUrl(organizationId, projectId) + '/members');
  }

  createOrUpdateProjectMembership(
    organizationId: Id,
    projectId: Id,
    userId: Id,
    accessLevel: ProjectAccessLevelEnumModelType
  ) {
    return post(
      buildProjectUrl(organizationId, projectId) + '/members/' + uri(userId),
      {
        access_level: accessLevel
      }
    );
  }

  deleteProjectMembership(organizationId: Id, projectId: Id, userId: Id) {
    return destroy(
      buildProjectUrl(organizationId, projectId) + '/members/' + uri(userId)
    );
  }

  getProjectAssistantStats(organizationId: Id, projectId: Id) {
    return get(
      '/organizations/' +
        uri(organizationId) +
        '/projects/' +
        uri(projectId) +
        '/assistant_stats'
    );
  }

  // == attachments ==
  uploadAttachment(
    attachmentType: string,
    elementType: string,
    elementId: Id | undefined,
    file: File,
    projectId: Id | undefined,
    rotation: AttachmentRotationEnum | undefined
  ) {
    return post(
      '/attachments',
      convertToFormData({
        project_id: projectId,
        attachment: {
          element_type: elementType,
          element_id: elementId,
          attachment_type: attachmentType,
          file: { file },
          rotation
        }
      })
    );
  }

  deleteAttachment(attachmentId: Id) {
    return destroy('/attachments/' + uri(attachmentId));
  }

  // == comments ==
  getComment(commentId: Id) {
    return get('/comments/' + uri(commentId));
  }

  createComment(comment: UnknownData) {
    return post('/comments', {
      comment
    });
  }

  updateComment(commentId: Id, commentPatch: UnknownData) {
    return patch('/comments/' + uri(commentId), {
      comment: commentPatch
    });
  }

  deleteComment(commentId: Id) {
    return destroy('/comments/' + uri(commentId));
  }

  getRating(ratingId: Id) {
    return get('/ratings/' + uri(ratingId));
  }

  createRating(rating: UnknownData) {
    return post('/ratings', {
      rating
    });
  }

  updateRating(ratingId: Id, ratingPatch: UnknownData) {
    return patch('/ratings/' + uri(ratingId), {
      rating: ratingPatch
    });
  }

  deleteRating(ratingId: Id) {
    return destroy('/ratings/' + uri(ratingId));
  }

  // == bookmarks ==
  getBookmarks(
    elementTypes: SearchableElementType | undefined,
    limit: number,
    page?: number
  ) {
    return get(
      '/bookmarks?' +
        QueryString.stringify(
          {
            element_types: elementTypes,
            limit,
            page
          },
          {
            arrayFormat: 'bracket'
          }
        )
    );
  }

  createBookmark(elementType: string, elementId: Id) {
    return post('/bookmarks', {
      bookmark: {
        element_type: elementType,
        element_id: elementId
      }
    });
  }

  deleteBookmark(bookmarkId: Id) {
    return destroy('/bookmarks/' + uri(bookmarkId));
  }

  // == briefing ==
  getBriefing(organizationId: Id, projectId: Id) {
    return get(buildProjectUrl(organizationId, projectId) + '/briefing');
  }

  createBriefing(organizationId: Id, projectId: Id, briefing: UnknownData) {
    return post(buildProjectUrl(organizationId, projectId) + '/briefing', {
      briefing
    });
  }

  updateBriefing(
    organizationId: Id,
    projectId: Id,
    briefingPatch: UnknownData
  ) {
    return patch(buildProjectUrl(organizationId, projectId) + '/briefing', {
      briefing: briefingPatch
    });
  }

  // == clusters ==
  getClusters(organizationId: Id, projectId: Id) {
    return get(buildProjectUrl(organizationId, projectId) + '/clusters');
  }

  createCluster(organizationId: Id, projectId: Id, cluster: UnknownData) {
    return post(buildProjectUrl(organizationId, projectId) + '/clusters', {
      cluster
    });
  }

  updateCluster(
    organizationId: Id,
    projectId: Id,
    clusterId: Id,
    clusterPatch: UnknownData
  ) {
    return patch(
      buildProjectUrl(organizationId, projectId) +
        '/clusters/' +
        uri(clusterId),
      {
        cluster: clusterPatch
      }
    );
  }

  deleteCluster(organizationId: Id, projectId: Id, clusterId: Id) {
    return destroy(
      buildProjectUrl(organizationId, projectId) + '/clusters/' + uri(clusterId)
    );
  }

  // == painpoints ==
  getPainpoints(organizationId: Id, projectId: Id) {
    return get(buildProjectUrl(organizationId, projectId) + '/painpoints');
  }

  getPainpoint(organizationId: Id, projectId: Id, painpointId: Id) {
    return get(
      buildProjectUrl(organizationId, projectId) +
        '/painpoints/' +
        uri(painpointId)
    );
  }

  createPainpoint(organizationId: Id, projectId: Id, painpoint: UnknownData) {
    return post(buildProjectUrl(organizationId, projectId) + '/painpoints', {
      painpoint
    });
  }

  requestPainpoint(organizationId: Id, projectId: Id, painpointId: Id) {
    return post(
      buildProjectUrl(organizationId, projectId) +
        '/painpoints/' +
        uri(painpointId) +
        '/request_assignment',
      {}
    );
  }

  assignPainpoint(
    organizationId: Id,
    projectId: Id,
    painpointId: Id,
    invitationText: string
  ) {
    return post(
      buildProjectUrl(organizationId, projectId) +
        '/painpoints/' +
        uri(painpointId) +
        '/assign',
      {
        invitation_text: invitationText
      }
    );
  }

  declinePainpoint(organizationId: Id, projectId: Id, painpointId: Id) {
    return post(
      buildProjectUrl(organizationId, projectId) +
        '/painpoints/' +
        uri(painpointId) +
        '/decline_assignment',
      {}
    );
  }

  updatePainpoint(
    organizationId: Id,
    projectId: Id,
    painpointId: Id,
    painpointPatch: UnknownData
  ) {
    return patch(
      buildProjectUrl(organizationId, projectId) +
        '/painpoints/' +
        uri(painpointId),
      {
        painpoint: painpointPatch
      }
    );
  }

  deletePainpoint(organizationId: Id, projectId: Id, painpointId: Id) {
    return destroy(
      buildProjectUrl(organizationId, projectId) +
        '/painpoints/' +
        uri(painpointId)
    );
  }

  createPainpointAim(
    organizationId: Id,
    projectId: Id,
    painpointId: Id,
    aim: UnknownData
  ) {
    return post(
      buildProjectUrl(organizationId, projectId) +
        '/painpoints/' +
        uri(painpointId) +
        '/aims',
      {
        painpoint_aim: aim
      }
    );
  }

  updatePainpointAim(
    organizationId: Id,
    projectId: Id,
    painpointId: Id,
    aimId: Id,
    aimPatch: UnknownData
  ) {
    return patch(
      buildProjectUrl(organizationId, projectId) +
        '/painpoints/' +
        uri(painpointId) +
        '/aims/' +
        uri(aimId),
      {
        painpoint_aim: aimPatch
      }
    );
  }

  deletePainpointAim(
    organizationId: Id,
    projectId: Id,
    painpointId: Id,
    aimId: Id
  ) {
    return destroy(
      buildProjectUrl(organizationId, projectId) +
        '/painpoints/' +
        uri(painpointId) +
        '/aims/' +
        uri(aimId)
    );
  }

  createPainpointQuestion(
    organizationId: Id,
    projectId: Id,
    painpointId: Id,
    question: UnknownData
  ) {
    return post(
      buildProjectUrl(organizationId, projectId) +
        '/painpoints/' +
        uri(painpointId) +
        '/questions',
      {
        painpoint_question: question
      }
    );
  }

  updatePainpointQuestion(
    organizationId: Id,
    projectId: Id,
    painpointId: Id,
    questionId: Id,
    questionPatch: UnknownData
  ) {
    return patch(
      buildProjectUrl(organizationId, projectId) +
        '/painpoints/' +
        uri(painpointId) +
        '/questions/' +
        uri(questionId),
      {
        painpoint_question: questionPatch
      }
    );
  }

  deletePainpointQuestion(
    organizationId: Id,
    projectId: Id,
    painpointId: Id,
    questionId: Id
  ) {
    return destroy(
      buildProjectUrl(organizationId, projectId) +
        '/painpoints/' +
        uri(painpointId) +
        '/questions/' +
        uri(questionId)
    );
  }

  // == benchmarks ==
  getBenchmarks(organizationId: Id, projectId: Id) {
    return get(buildProjectUrl(organizationId, projectId) + '/benchmarks');
  }

  getBenchmark(organizationId: Id, projectId: Id, benchmarkId: Id) {
    return get(
      buildProjectUrl(organizationId, projectId) +
        '/benchmarks/' +
        uri(benchmarkId)
    );
  }

  getBenchmarkLogosByCompanyName(companyName: string) {
    return get('/benchmark?company_name=' + uri(companyName));
  }

  createBenchmark(organizationId: Id, projectId: Id, benchmark: UnknownData) {
    return post(buildProjectUrl(organizationId, projectId) + '/benchmarks', {
      benchmark
    });
  }

  updateBenchmark(
    organizationId: Id,
    projectId: Id,
    benchmarkId: Id,
    benchmarkPatch: UnknownData
  ) {
    return patch(
      buildProjectUrl(organizationId, projectId) +
        '/benchmarks/' +
        uri(benchmarkId),
      {
        benchmark: benchmarkPatch
      }
    );
  }

  deleteBenchmark(organizationId: Id, projectId: Id, benchmarkId: Id) {
    return destroy(
      buildProjectUrl(organizationId, projectId) +
        '/benchmarks/' +
        uri(benchmarkId)
    );
  }

  getSuggestions(name: string) {
    return get(`/autocompletes/company_name?company_name=${name}`);
  }

  generateGeneralBenchmarksByAi(organizationId: number, projectId: number) {
    return get(
      `/organizations/${organizationId}/llm/projects/${projectId}/generate_benchmarks`
    );
  }

  generateMoreGeneralBenchmarksByAi(
    organizationId: number,
    projectId: number,
    radical: boolean = false
  ) {
    return get(
      `/organizations/${organizationId}/llm/projects/${projectId}/generate_more_benchmarks?radical=${radical}`
    );
  }

  generateGeneralBenchmarksForPainpointsByAi(
    organizationId: number,
    projectId: number,
    painpointIds: number[]
  ) {
    return get(
      `/organizations/${organizationId}/llm/projects/${projectId}/generate_benchmarks_for_painpoints?painpoint_ids=${painpointIds}`
    );
  }

  // == hypotheses ==
  getHypotheses(organizationId: Id, projectId: Id) {
    return get(buildProjectUrl(organizationId, projectId) + '/hypotheses');
  }

  getHypothesis(organizationId: Id, projectId: Id, hypothesisId: Id) {
    return get(
      buildProjectUrl(organizationId, projectId) +
        '/hypotheses/' +
        uri(hypothesisId)
    );
  }

  createHypothesis(organizationId: Id, projectId: Id, hypothesis: UnknownData) {
    return post(buildProjectUrl(organizationId, projectId) + '/hypotheses', {
      hypothesis
    });
  }

  requestHypothesis(organizationId: Id, projectId: Id, hypothesisId: Id) {
    return post(
      buildProjectUrl(organizationId, projectId) +
        '/hypotheses/' +
        uri(hypothesisId) +
        '/request_assignment',
      {}
    );
  }

  assignHypothesis(
    organizationId: Id,
    projectId: Id,
    hypothesisId: Id,
    invitationText: string
  ) {
    return post(
      buildProjectUrl(organizationId, projectId) +
        '/hypotheses/' +
        uri(hypothesisId) +
        '/assign',
      {
        invitation_text: invitationText
      }
    );
  }

  declineHypothesis(organizationId: Id, projectId: Id, hypothesisId: Id) {
    return post(
      buildProjectUrl(organizationId, projectId) +
        '/hypotheses/' +
        uri(hypothesisId) +
        '/decline_assignment',
      {}
    );
  }

  updateHypothesis(
    organizationId: Id,
    projectId: Id,
    hypothesisId: Id,
    hypothesisPatch: UnknownData
  ) {
    return patch(
      buildProjectUrl(organizationId, projectId) +
        '/hypotheses/' +
        uri(hypothesisId),
      {
        hypothesis: hypothesisPatch
      }
    );
  }

  deleteHypothesis(organizationId: Id, projectId: Id, hypothesisId: Id) {
    return destroy(
      buildProjectUrl(organizationId, projectId) +
        '/hypotheses/' +
        uri(hypothesisId)
    );
  }

  generateHypothesesByAi(
    organizationId: number,
    projectId: number,
    painpointIds: number[],
    benchmarkIds: number[]
  ) {
    return get(
      `/organizations/${organizationId}/llm/projects/${projectId}/generate_hypothesis?painpoint_ids=${painpointIds}&benchmark_ids=${benchmarkIds}`
    );
  }

  generateNewHypothesesImage(
    organizationId: number,
    projectId: number,
    hypothesisId: number,
    prompt: string
  ) {
    return post(
      buildProjectUrl(organizationId, projectId) +
        `/hypotheses/${hypothesisId}/generate_image`,
      {
        image_prompt: prompt
      }
    );
  }
  // == prototypes ==
  getPrototypes(organizationId: Id, projectId: Id) {
    return get(buildProjectUrl(organizationId, projectId) + '/prototypes');
  }

  getPrototype(organizationId: Id, projectId: Id, prototypeId: Id) {
    return get(
      buildProjectUrl(organizationId, projectId) +
        '/prototypes/' +
        uri(prototypeId)
    );
  }

  createPrototype(organizationId: Id, projectId: Id, prototype: UnknownData) {
    return post(buildProjectUrl(organizationId, projectId) + '/prototypes', {
      prototype
    });
  }

  updatePrototype(
    organizationId: Id,
    projectId: Id,
    prototypeId: Id,
    prototypePatch: UnknownData
  ) {
    return patch(
      buildProjectUrl(organizationId, projectId) +
        '/prototypes/' +
        uri(prototypeId),
      {
        prototype: prototypePatch
      }
    );
  }

  deletePrototype(organizationId: Id, projectId: Id, prototypeId: Id) {
    return destroy(
      buildProjectUrl(organizationId, projectId) +
        '/prototypes/' +
        uri(prototypeId)
    );
  }

  generatePrototypesByAi(
    organizationId: number,
    projectId: number,
    hypothesesIds: number[]
  ) {
    return get(
      `/organizations/${organizationId}/llm/projects/${projectId}/generate_prototypes?hypotheses_ids=${hypothesesIds}`
    );
  }
  createPrototypeScreen(
    organizationId: Id,
    projectId: Id,
    prototypeId: Id | undefined,
    assistantSessionId: Id | undefined,
    screen: UnknownData
  ) {
    return post(buildProjectUrl(organizationId, projectId) + '/screens', {
      prototype_screen: screen,
      assisant_session_id: assistantSessionId,
      prototype_id: prototypeId
    });
  }

  updatePrototypeScreen({
    organizationId,
    projectId,
    screenId,
    screenPatch,
    prototypeId,
    assistantSessionId
  }: IPrototypeScreenBase) {
    return patch(
      buildProjectUrl(organizationId, projectId) + '/screens/' + uri(screenId),
      {
        prototype_screen: screenPatch,
        prototype_id: prototypeId,
        assistant_session_id: assistantSessionId
      }
    );
  }

  deletePrototypeScreen({
    organizationId,
    projectId,
    screenId,
    prototypeId,
    assistantSessionId
  }: IPrototypeScreenBase) {
    return destroy(
      buildProjectUrl(organizationId, projectId) + '/screens/' + uri(screenId),
      {
        prototype_id: prototypeId,
        assistant_session_id: assistantSessionId
      }
    );
  }

  swapPrototypeScreens({
    organizationId,
    projectId,
    screenId,
    screen2Id,
    prototypeId,
    assistantSessionId
  }: IPrototypeScreenSwap) {
    return post(buildProjectUrl(organizationId, projectId) + '/screens/swap', {
      screen1_id: screenId,
      screen2_id: screen2Id,
      prototype_id: prototypeId,
      assistant_session_id: assistantSessionId
    });
  }

  // == learnings ==
  getLearnings(organizationId: Id, projectId: Id) {
    return get(buildProjectUrl(organizationId, projectId) + '/learnings');
  }

  getLearning(organizationId: Id, projectId: Id, learningId: Id) {
    return get(
      buildProjectUrl(organizationId, projectId) +
        '/learnings/' +
        uri(learningId)
    );
  }

  createLearning(organizationId: Id, projectId: Id, learning: UnknownData) {
    return post(buildProjectUrl(organizationId, projectId) + '/learnings', {
      learning
    });
  }

  updateLearning(
    organizationId: Id,
    projectId: Id,
    learningId: Id,
    learningPatch: UnknownData
  ) {
    return patch(
      buildProjectUrl(organizationId, projectId) +
        '/learnings/' +
        uri(learningId),
      {
        learning: learningPatch
      }
    );
  }

  deleteLearning(organizationId: Id, projectId: Id, learningId: Id) {
    return destroy(
      buildProjectUrl(organizationId, projectId) +
        '/learnings/' +
        uri(learningId)
    );
  }

  // == copy ==
  copyElement(elementId: Id, elementType: ElementType, targetProjectId: Id) {
    return post('/copy', {
      element_id: elementId,
      element_type: elementType,
      target_project_id: targetProjectId
    });
  }

  // == pdf export ==
  downloadPdf(elementType: ElementType, ids?: number[]) {
    const elementTypeToPath: { [key in ElementType]?: string } = {
      Benchmark: 'benchmarks',
      Hypothesis: 'hypotheses',
      Prototype: 'prototypes',
      Learning: 'learnings',
      Painpoint: 'painpoints'
    };

    return post(
      `/pdf/${uri(elementTypeToPath[elementType] as string)}`,
      { ids },
      { returnFile: true }
    );
  }

  downloadZip(
    elements: Partial<Pick<TypedElementMap, DownloadableElementType>>
  ) {
    return post(
      '/pdf/create',
      {
        benchmark_ids: elements.Benchmark || [],
        hypothesis_ids: elements.Hypothesis || [],
        prototype_ids: elements.Prototype || [],
        learning_ids: elements.Learning || [],
        painpoint_ids: elements.Painpoint || []
      },
      {
        returnFile: true
      }
    );
  }

  // == shared links ==
  getSharedContent(token: string, password?: string) {
    if (!password) {
      return get('/sharing_links/' + uri(token));
    }

    return post('/sharing_links/' + uri(token), {
      password
    });
  }

  createSharingLink(
    password: string,
    elements: Partial<Pick<TypedElementMap, DownloadableElementType>>
  ) {
    return post('/sharing_links/', {
      sharing_link: {
        password,
        benchmark_ids: elements.Benchmark || [],
        hypothesis_ids: elements.Hypothesis || [],
        prototype_ids: elements.Prototype || [],
        learning_ids: elements.Learning || [],
        painpoint_ids: elements.Painpoint || []
      }
    });
  }

  // == gpt user project settings ==
  updateProjectUserSettings(
    organizationId: Id,
    projectId: Id,
    benchmarkCount: number,
    selectedRegion: string,
    selectedIndustry: string
  ) {
    return post(
      `/organizations/${organizationId}/projects/${projectId}/project_user_settings`,
      {
        settings: {
          amount_of_benchmarks_for_painpoints: benchmarkCount,
          selected_region: selectedRegion,
          selected_industry: selectedIndustry
        }
      }
    );
  }

  getRegions() {
    return get('/available_regions');
  }

  getIndustries() {
    return get('/available_industries');
  }

  getProjectUserSettings(organizationId: Id, projectId: Id) {
    return get(
      `/organizations/${organizationId}/projects/${projectId}/project_user_settings`
    );
  }

  // == search ==
  search({
    query,
    elementTypes,
    limit,
    page,
    projectId,
    organizationId
  }: {
    query: string;
    elementTypes: SearchableElementType | undefined;
    limit: number;
    page?: number;
    projectId?: number;
    organizationId?: number;
  }) {
    return get(
      '/search?' +
        QueryString.stringify(
          {
            query,
            element_types: elementTypes,
            limit,
            page,
            project_id: projectId,
            organization_id: organizationId
          },
          {
            arrayFormat: 'bracket'
          }
        )
    );
  }

  // == recommendations ==
  getRecommendations(
    elementType: RecommendableElementType,
    organizationId: Id,
    projectId: Id
  ) {
    if (!elementType || !organizationId || !projectId) {
      return;
    }
    return get(
      `/organizations/${organizationId}/projects/${projectId}/recommendations?${QueryString.stringify(
        {
          element_type: elementType || undefined
        }
      )}`
    );
  }

  // == gpt request counter ==
  getGPTRequestCounter(organizationId: Id, projectId: Id) {
    return get(
      `/organizations/${organizationId}/projects/${projectId}/gpt_request_counters`
    );
  }

  // == assistant sessions ==
  getAssistantSessions({
    organizationId = '',
    authorId = ''
  }: IGetAssistantSessions) {
    return get(
      `/assistant_sessions?organization_id=${organizationId}&author_id=${authorId}`
    );
  }

  getSentAssistantInvitations(projectId?: number) {
    return get(
      '/assistant_sessions/sent_invitations' +
        (projectId ? '?project_id=' + projectId : '')
    );
  }

  getAssistantSession(id: Id) {
    return get('/assistant_sessions/' + uri(id));
  }

  getAssistantReceivedInvitationsCount() {
    return get('/assistant_sessions/received_invitations_count');
  }

  generatePainpointsByAi(organizationId: number, projectId: number) {
    return get(
      `/organizations/${organizationId}/llm/projects/${projectId}/generate_painpoints`
    );
  }

  generateMorePainpointsByAi(
    organizationId: number,
    projectId: number,
    clusterId: number
  ) {
    return get(
      `/organizations/${organizationId}/llm/projects/${projectId}/generate_painpoints_for_cluster/${clusterId}`
    );
  }

  createAssistantSession(data: UnknownData) {
    return post('/assistant_sessions', {
      assistant_session: data
    });
  }

  createAssistantSessionInvitation(
    projectId: Id,
    invitedUserId: Id,
    sessionType: AssistantType,
    invitationText?: string,
    benchmarkIds?: number[],
    painpointId?: number,
    hypothesisId?: number
  ) {
    return post('/assistant_sessions', {
      assistant_session: {
        project_id: projectId,
        benchmark_ids: benchmarkIds || undefined,
        painpoint_id: painpointId || undefined,
        hypothesis_id: hypothesisId || undefined
      },
      invited_user_id: invitedUserId,
      invitation_session_type: sessionType,
      invitation_text: invitationText
    });
  }

  reinviteAssistantSession(id: Id) {
    return post('/assistant_sessions/' + uri(id) + '/reinvite', {});
  }

  updateAssistantSession(id: Id, data: UnknownData) {
    return patch('/assistant_sessions/' + uri(id), {
      assistant_session: data
    });
  }

  deleteAssistantSession(id: Id) {
    return destroy('/assistant_sessions/' + uri(id));
  }

  publishAssistantSession(id: Id) {
    return post('/assistant_sessions/' + uri(id) + '/publish', {});
  }

  createAssistantSessionSolution(assistantSessionId: Id, data: UnknownData) {
    return post(
      '/assistant_sessions/' +
        uri(assistantSessionId) +
        '/assistant_session_solutions',
      {
        assistant_session_solution: data
      }
    );
  }

  updateAssistantSessionSolution(
    assistantSessionId: Id,
    id: Id,
    data: UnknownData
  ) {
    return patch(
      '/assistant_sessions/' +
        uri(assistantSessionId) +
        '/assistant_session_solutions/' +
        uri(id),
      {
        assistant_session_solution: data
      }
    );
  }

  deleteAssistantSessionSolution(assistantSessionId: Id, id: Id) {
    return destroy(
      '/assistant_sessions/' +
        uri(assistantSessionId) +
        '/assistant_session_solutions/' +
        uri(id)
    );
  }

  createAssistantSessionBenchmark(assistantSessionId: Id, data: UnknownData) {
    return post(
      '/assistant_sessions/' +
        uri(assistantSessionId) +
        '/assistant_session_benchmarks',
      {
        assistant_session_benchmark: data
      }
    );
  }

  updateAssistantSessionBenchmark(
    assistantSessionId: Id,
    id: Id,
    data: UnknownData
  ) {
    return patch(
      '/assistant_sessions/' +
        uri(assistantSessionId) +
        '/assistant_session_benchmarks/' +
        uri(id),
      {
        assistant_session_benchmark: data
      }
    );
  }

  deleteAssistantSessionBenchmark(assistantSessionId: Id, id: Id) {
    return destroy(
      '/assistant_sessions/' +
        uri(assistantSessionId) +
        '/assistant_session_benchmarks/' +
        uri(id)
    );
  }

  // == faq pages ==

  getFaqPages() {
    return get('/faq_pages');
  }

  getFaqPage(id: Id) {
    return get('/faq_pages/' + uri(id));
  }

  // == network ==
  getRecentNetworkElements(
    elementTypes: SearchableElementType | undefined,
    limit: number,
    page?: number
  ) {
    return get(
      '/networks/newest?' +
        QueryString.stringify(
          {
            element_types: elementTypes,
            limit,
            page
          },
          {
            arrayFormat: 'bracket'
          }
        )
    );
  }

  getNetworkOrganizations() {
    return get('/networks');
  }

  // == helpers ==
  isUnauthorized(error: { response?: { status: number } }) {
    return error && error.response && error.response.status === 401;
  }

  isAccessDenied(error: { response?: { status: number } }) {
    return error && error.response && error.response.status === 403;
  }

  isNotFound(error: { response?: { status: number } }) {
    return error && error.response && error.response.status === 404;
  }

  isFormError(error: { response?: { status: number } }) {
    return error && error.response && error.response.status === 422;
  }
}

export default DisrooptiveApi;
