import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import InviteFlow from 'components/CallToAction/InviteFlow';
import CardColumn from 'components/CardColumn';
import CardColumnHeader from 'components/CardColumn/CardColumnHeader';
import ErrorMessage from 'components/ErrorMessage';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import TrelloWrapper from 'components/Layout/TrelloWrapper';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import SimplePopup from 'components/SimplePopup';
import Typography from 'components/Typography';
import ActionsOverlayContainer from 'containers/ActionsOverlayContainer';
import PainpointCreateContainer from 'containers/PainpointCreateContainer';
import { ActionsStoreType } from 'models/ActionsStore';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { PainpointModelType } from 'models/PainpointModel';
import { ClusterPainpoints, PainpointsStoreType } from 'models/PainpointsStore';
import { history, HistoryProps, isPush } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';
import { isDefined } from 'utils/misc/is-defined';
import { DeclineActionType } from './PainpointsListItemMenu';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggablePainPointItem } from './DraggablePainpointItem';

// tslint:disable-next-line: no-empty-interface
interface PublicPainpointsListScreenProps {}

interface PainpointsListScreenState {
  copyToProjectOverlayActive: boolean;
  requestLoading?: boolean;
  invitePainpoint?: number;
}

interface PainpointsListScreenProps extends PublicPainpointsListScreenProps {
  actionsStore: ActionsStoreType;
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  painpointsStore: PainpointsStoreType;
  form: FormType;
}

@inject('actionsStore', 'applicationStore', 'dataStore', 'painpointsStore')
@observer
class PainpointsListScreen extends React.Component<
  PainpointsListScreenProps & HistoryProps & WrappedComponentProps,
  PainpointsListScreenState
> {
  state: PainpointsListScreenState = {
    copyToProjectOverlayActive: false
  };

  componentDidMount() {
    this.loadPainpoints(!isPush(this.props.history));
  }

  loadPainpoints(checkIfAlreadyInStore = false) {
    const { dataStore, painpointsStore } = this.props;

    if (checkIfAlreadyInStore && dataStore.painpointsList) {
      return;
    }

    painpointsStore.getPainpoints();
  }

  checkChanged(element: PainpointModelType, newChecked: boolean) {
    const { dataStore } = this.props;

    const organizationId = dataStore.currentOrganizationId;
    const projectId = dataStore.currentProjectId;

    if (!isDefined(organizationId) || !isDefined(projectId)) {
      return;
    }

    this.props.actionsStore.selectionSet(
      {
        Painpoint: [
          {
            id: element.id,
            organizationId,
            projectId,
            publishState: element.publish_state
          }
        ]
      },
      newChecked
    );
  }

  async deletePainpoints(ids: number[]) {
    const { actionsStore, intl, painpointsStore } = this.props;

    if (!isDefined(ids) || ids.length < 1) {
      return;
    }

    // TODO create nicer confirm?
    if (
      !window.confirm(
        intl.formatMessage(
          { id: 'remove painpoints confirm' },
          { count: ids.length }
        )
      )
    ) {
      return;
    }

    await painpointsStore.bulkDeletePainpoints(ids);
    actionsStore.selectionClear();
  }

  closePopup() {
    this.props.history.replace('painpoints');
  }

  maybeRenderCreatePopup() {
    const { location, painpointsStore, history } = this.props;

    const action = location?.query?.action;
    if (!action) {
      return null;
    }

    if (action === 'create') {
      if (!painpointsStore.hasAnyClusters) {
        return (
          <SimplePopup
            onAbort={() => this.closePopup()}
            onSubmit={() => this.closePopup()}
            submitTextId="OK"
          >
            <h2>
              <FormattedMessage id="Create painpoint" />
            </h2>

            <Typography>
              <FormattedMessage id="painpoint no cluster" />
            </Typography>
          </SimplePopup>
        );
      }

      const id =
        location?.query?.cluster_id || painpointsStore.clustersByName[0].id;

      return (
        <PainpointCreateContainer
          clusterId={id}
          onClose={() => this.closePopup()}
        />
      );
    }

    if (action === 'edit') {
      const id = location?.query?.painpoint_id;
      if (!id) {
        return null;
      }

      const painpoint = this.props.dataStore.painpoints.get(id.toString());
      if (!painpoint) {
        return null;
      }

      return (
        <PainpointCreateContainer
          painpoint={painpoint}
          onClose={() => history.replace('painpoints')}
        />
      );
    }

    return null;
  }

  private editClick(id: number) {
    this.props.history.push('painpoints?action=edit&painpoint_id=' + id);
  }

  private async requestClick(id: number) {
    const { applicationStore, painpointsStore, intl } = this.props;

    this.setState({
      requestLoading: true
    });

    try {
      if (!(await painpointsStore.requestPainpoint(id))) {
        alert(intl.formatMessage({ id: 'painpoint already requested error' }));
      }
    } catch (error) {
      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'request failed error' }),
        'error'
      );
    }

    this.setState({
      requestLoading: false
    });
  }

  private async declineClick(id: number, type: DeclineActionType) {
    const { applicationStore, painpointsStore, intl } = this.props;

    this.setState({
      requestLoading: true
    });

    try {
      await painpointsStore.declinePainpoint(id);
    } catch (error) {
      applicationStore.setFlashMessage(
        intl.formatMessage({
          id:
            type === 'retract' ? 'retract failed error' : 'decline failed error'
        }),
        'error'
      );
    }

    this.setState({
      requestLoading: false
    });
  }

  private async assignClick(id: number) {
    const {
      applicationStore,
      painpointsStore,
      intl: { formatMessage }
    } = this.props;

    this.setState({
      requestLoading: true
    });

    try {
      await painpointsStore.assignPainpoint(
        id,
        formatMessage(
          { id: 'invitation template hypothesis_sprint' },
          {
            project: applicationStore.currentProject?.topic || 'N/A',
            inviter: applicationStore.currentUser?.first_name || ''
          }
        )
      );

      applicationStore.setFlashMessage(
        formatMessage({
          id: 'painpoint assigned'
        })
      );
    } catch (error: any) {
      switch (error.body?.error) {
        case 'Not an editor':
          alert(formatMessage({ id: 'not an editor error' }));
          break;

        case 'No assignee':
          alert(formatMessage({ id: 'no assignee error' }));
          break;

        default:
          applicationStore.setFlashMessage(
            formatMessage({
              id: 'assign failed error'
            }),
            'error'
          );
      }
    }

    this.setState({
      requestLoading: false
    });
  }

  private inviteClick(painpointId: number) {
    this.setState({
      invitePainpoint: painpointId
    });
  }

  private async searchBenchmarks(painpointId: number) {
    const {
      applicationStore,
      painpointsStore,
      actionsStore,
      dataStore,
      intl: { formatMessage }
    } = this.props;

    const organizationId = dataStore.currentOrganizationId!;
    const projectId = dataStore.currentProjectId!;
    const painpointIds = [painpointId];

    actionsStore.selectionClear();
    try {
      await painpointsStore.findBenchmarksByAi(
        organizationId,
        projectId,
        painpointIds
      );
      history.push(`/app/lab/${organizationId}/${projectId}/benchmarks`);
    } catch (error: any) {
      applicationStore.setFlashMessage(
        formatMessage({
          id: 'benchmark ai failed error'
        }),
        'error'
      );
    }
  }

  private finishInvite() {
    this.setState({
      invitePainpoint: undefined
    });
  }

  renderList() {
    const { actionsStore, painpointsStore, dataStore, applicationStore } =
      this.props;
    const { invitePainpoint } = this.state;

    const isOrgAdmin = dataStore.isOrgAdmin;
    const currentUserId = dataStore.currentUserId;
    const isProjectEditor = dataStore.isProjectEditor;

    const grid = painpointsStore.grid();
    const checked: number[] = actionsStore.selectedIds.Painpoint;

    return this.renderPage(
      <>
        {(painpointsStore.isListDeleting || this.state.requestLoading) &&
          !applicationStore.isPrefillingPainPointClusters && <Loading />}

        <DndProvider backend={HTML5Backend}>
          <TrelloWrapper>
            {grid.map((column: ClusterPainpoints) => {
              const clusterId = column.cluster?.id;
              return (
                // TODO: OnAddCard needs to redirect to right Tag
                <CardColumn
                  key={clusterId || '_null'}
                  cluster={column.cluster}
                  count={column.painpoints.length}
                  nameChangeOnBlur={true}
                  fixHeight={6}
                  mayEdit={isProjectEditor}
                  isLoading={column.cluster?.isBeeingFilled}
                  onAddCard={
                    !column.cluster
                      ? undefined
                      : () =>
                          this.props.history.push(
                            'painpoints?action=create&cluster_id=' +
                              (clusterId || 'new')
                          )
                  }
                >
                  <ColumnWrapper gap="1em">
                    {column.painpoints.map((element: PainpointModelType) => {
                      const { id } = element;

                      return (
                        <DraggablePainPointItem
                          key={id}
                          painpoint={element}
                          checked={checked.indexOf(id) > -1}
                          onChange={(c) => this.checkChanged(element, c)}
                          currentUserId={currentUserId}
                          isAdmin={dataStore.isAdmin}
                          isOrgAdmin={isOrgAdmin}
                          isProjectEditor={isProjectEditor}
                          project={dataStore.currentProject}
                          onEditClick={() => this.editClick(id)}
                          onRemoveClick={() => this.deletePainpoints([id])}
                          onRequestClick={() => this.requestClick(id)}
                          onAssignClick={() => this.assignClick(id)}
                          onDeclineClick={(t) => this.declineClick(id, t)}
                          onInviteClick={() => this.inviteClick(id)}
                          onSearchBenchmarksClick={() =>
                            this.searchBenchmarks(id)
                          }
                        />
                      );
                    })}
                  </ColumnWrapper>
                </CardColumn>
              );
            })}

            {(dataStore.isAdmin ||
              (isProjectEditor &&
                (!dataStore!.organization!.cluster_limit ||
                  (dataStore!.organization!.cluster_limit > 0 &&
                    dataStore.clusters.size <
                      dataStore!.organization!.cluster_limit)))) && (
              <CardColumnHeader add={true} changeOnBlur={true} />
            )}
          </TrelloWrapper>
        </DndProvider>

        <InviteFlow
          assistantType="hypothesis_sprint"
          active={!!invitePainpoint}
          painpointId={invitePainpoint}
          onFinish={() => this.finishInvite()}
        />

        {!painpointsStore.isListBusy && (
          <ActionsOverlayContainer
            showCopy={true}
            showAiBenchmark={true}
            showAiBriefing={true}
            showDelete={
              actionsStore.selectedFromProjectIds.length === 1 &&
              actionsStore.selectedFromProjectIds[0] ===
                dataStore.currentProjectId
            }
            showShare={true}
          />
        )}
        {this.maybeRenderCreatePopup()}
      </>
    );
  }

  renderLoading() {
    // TODO
    return this.renderPage(<Loading />);
  }

  renderPage(content: any) {
    const { dataStore } = this.props;

    return (
      <>
        <div className="l-trello-wrapper__page-content-wrapper">
          <PageHeader
            titleId="Painpoints"
            logoHeader={
              // tslint:disable-next-line: jsx-wrap-multiline
              <PageLogoHeader
                title={<FormattedMessage id="painpoint list header" />}
                subtitle={dataStore.currentProject?.briefing?.question}
                list={true}
                extraPadding={true}
              />
            }
          />
          {content}
        </div>
      </>
    );
  }

  render() {
    const { painpointsStore } = this.props;

    if (painpointsStore.isListLoading) {
      return this.renderLoading();
    }

    if (painpointsStore.isListError) {
      return this.renderPage(
        <ErrorMessage
          state={painpointsStore.listLoadingState}
          onRetry={() => this.loadPainpoints()}
        />
      );
    }

    return this.renderList();
  }
}

export default injectIntl((props: PublicPainpointsListScreenProps) => {
  const form = useForm({ sort: 'newest' });
  // @ts-ignore
  return <PainpointsListScreen {...props} form={form} />;
});
