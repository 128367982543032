import { useState, useEffect } from 'react';
import SimplePopup from 'components/SimplePopup';
import IconButton from 'components/IconButton';
import Input from 'components/Inputs/Input';
// import { useParams } from 'react-router-dom';
import { ActionsStoreType } from 'models/ActionsStore';
import { observer } from 'mobx-react';
import { inject } from 'mobx-react';
import { DataStoreType } from 'models/DataStore';
import Loading from 'components/Loading';

interface ISelectTopSettingsProps {
  setShowSettings: (value: boolean) => void;
  actionsStore?: ActionsStoreType;
  dataStore?: DataStoreType;
}

function SelectTopSettingsPopup({
  setShowSettings,
  actionsStore,
  dataStore
}: ISelectTopSettingsProps) {
  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState<string[]>([]);
  const [industries, setIndustries] = useState<string[]>([]);
  const [benchmarkCount, setBenchmarkCount] = useState(5);
  const [selectedRegion, setSelectedRegion] = useState('Global');
  const [selectedIndustry, setSelectedIndustry] = useState('Out of the Box');
  const [customRegion, setCustomRegion] = useState(false);
  const [customIndustry, setCustomIndustry] = useState(false);

  const organizationId = dataStore!.currentOrganizationId!;
  const projectId = dataStore!.currentProjectId!;

  useEffect(() => {
    const loadSettings = async () => {
      try {
        setLoading(true);
        const userSettings = await actionsStore!.getProjectUserSettings(
          organizationId,
          projectId
        );
        const regionsResponse = await actionsStore!.getRegions();
        const industriesResponse = await actionsStore!.getIndustries();

        setRegions(regionsResponse);
        setIndustries(industriesResponse);

        if (userSettings) {
          setBenchmarkCount(userSettings.amount_of_benchmarks_for_painpoints);
          setSelectedRegion(userSettings.selected_region);
          setSelectedIndustry(userSettings.selected_industry);
          setCustomRegion(userSettings.is_custom_region);
          setCustomIndustry(userSettings.is_custom_industry);
        }

        setLoading(false);
      } catch (error) {
        console.error('Failed to load settings:', error);
        setLoading(false);
      }
    };

    loadSettings();
  }, [actionsStore, dataStore, organizationId, projectId]);

  const handleSubmit = async () => {
    try {
      await actionsStore!.updateProjectUserSettings(
        dataStore!.currentOrganizationId!,
        dataStore!.currentProjectId!,
        benchmarkCount,
        selectedRegion,
        selectedIndustry
      );

      setShowSettings(false);
    } catch (error) {
      console.error('Failed to save settings:', error);
    }
  };
  return (
    <SimplePopup
      onAbort={() => setShowSettings(false)}
      onSubmit={handleSubmit}
      headlineTextId="Settings(local)"
      submitTextId="Save"
      reduced={true}
      maxHeight={true}
    >
      {loading ? (
        <div style={{ padding: '2em 0' }}>
          <Loading inline={true} />
        </div>
      ) : (
        <div className="settings-container">
          <div className="settings-section">
            <h2 className="settings-title">Generierte Benchmarks</h2>
            <div className="settings-counter">
              <span className="settings-counter-label">Anzahl</span>
              <div className="settings-counter-controls">
                <IconButton
                  onClick={() =>
                    setBenchmarkCount((prev) => Math.max(1, prev - 1))
                  }
                  iconName="minus"
                  label="minus"
                />
                <input
                  className="settings-counter-input"
                  value={benchmarkCount}
                  readOnly
                />
                <IconButton
                  onClick={() =>
                    setBenchmarkCount((prev) => Math.min(10, prev + 1))
                  }
                  iconName="plus"
                  label="plus"
                />
              </div>
            </div>
          </div>

          <div className="settings-section">
            <h2 className="settings-title">
              Länderregion für Benchmarks auswählen
            </h2>
            <div className="settings-selector">
              {regions.map((region) => (
                <button
                  key={region}
                  className={`settings-button ${
                    selectedRegion === region ? 'active' : ''
                  }`}
                  onClick={() => {
                    setSelectedRegion(region);
                    setCustomRegion(false);
                  }}
                >
                  {region}
                </button>
              ))}
            </div>
            <div className="settings-divider">oder</div>
            <Input
              normalFont={true}
              name="region"
              placeholder="Hier klicken um Sprachraum einzutippen"
              defaultValue={customRegion ? selectedRegion : ''}
              onChange={(e) => {
                setSelectedRegion(e.target.value);
                setCustomRegion(true);
              }}
            />
          </div>

          <div className="settings-section">
            <h2 className="settings-title">Branche für Benchmarks festlegen</h2>
            <div className="settings-selector">
              {industries.map((industry) => (
                <button
                  key={industry}
                  className={`settings-button ${
                    selectedIndustry === industry ? 'active' : ''
                  }`}
                  onClick={() => {
                    setSelectedIndustry(industry);
                    setCustomIndustry(false);
                  }}
                >
                  {industry}
                </button>
              ))}
            </div>
            <div className="settings-divider">oder</div>
            <Input
              normalFont={true}
              name="industry"
              placeholder="Hier klicken um Branche einzutippen"
              defaultValue={customIndustry ? selectedIndustry : ''}
              onChange={(e) => {
                setSelectedIndustry(e.target.value);
                setCustomIndustry(true);
              }}
            />
          </div>
        </div>
      )}
    </SimplePopup>
  );
}

export default inject(
  'dataStore',
  'actionsStore'
)(observer(SelectTopSettingsPopup));
