import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import CardWrapper from 'components/CardWrapper';
import DestroyButton from 'components/DestroyButton';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import classNames from 'classnames';

function detectScrollbarVisibility() {
  // Create a temporary container for testing
  const container = document.createElement('div');
  container.style.width = '100px';
  container.style.height = '100px';
  container.style.overflow = 'scroll'; // Force scrollbar
  container.style.visibility = 'hidden'; // Hide it from the page
  document.body.appendChild(container);

  // Check if the scrollbar takes up space (bold scrollbar) or is overlay (tiny)
  const scrollbarVisible = container.offsetWidth > container.clientWidth;

  // Cleanup
  document.body.removeChild(container);

  return scrollbarVisible;
}
interface SimplePopupProps {
  onAbort?: () => void;
  onSubmit?: () => void;
  onSecondaryClick?: () => void;
  isLoading?: boolean;
  isSubmitDisabled?: boolean;
  headlineTextId?: string;
  submitTextId?: string;
  secondaryTextId?: string;
  children?: React.ReactNode;
  reduced?: boolean;
  noPadding?: boolean;
  maxHeight?: boolean;
}

export default ({
  onAbort,
  onSubmit,
  onSecondaryClick,
  isLoading,
  isSubmitDisabled,
  headlineTextId,
  submitTextId,
  secondaryTextId,
  children,
  reduced,
  noPadding,
  maxHeight
}: SimplePopupProps) => {
  let dialogElement: HTMLDivElement | null = null;
  const [physicalMouse, setPhysicalMouse] = useState(false);
  // Focus dialog element initially
  useEffect(() => {
    setPhysicalMouse(detectScrollbarVisibility());

    if (dialogElement) {
      dialogElement.focus();
    }
  }, []);
  // Handle escape key
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const { activeElement } = document;
      if (
        onAbort &&
        event.key === 'Escape' &&
        (!activeElement ||
          !['INPUT', 'TEXTAREA', 'SELECT'].includes(activeElement.tagName))
      ) {
        onAbort();
      }
    };
    document.documentElement.addEventListener('keyup', handler);
    return () => {
      document.documentElement.removeEventListener('keyup', handler);
    };
  }, [onAbort]);
  return (
    <>
      <OverlayWrapper
        disabled={true}
        background="dark"
        center={true}
        reduced={reduced ? true : false}
        noPadding={noPadding}
      >
        <CardWrapper>
          <div
            ref={(el) => (dialogElement = el)}
            className={classNames('simple-popup', {
              'simple-popup--maxHeight': maxHeight,
              'simple-popup--physicalMouse': physicalMouse
            })}
            role="dialog"
            aria-modal="true"
            tabIndex={0}
          >
            {onAbort && <DestroyButton onClick={onAbort} />}

            {headlineTextId && (
              <h1 className="simple-popup__headline">
                <FormattedMessage id={headlineTextId} />
              </h1>
            )}

            {children && (
              <div className="simple-popup__children">{children}</div>
            )}

            {onSubmit && (
              <div className="simple-popup__submit-wrapper">
                <MainButton
                  disabled={isLoading || isSubmitDisabled}
                  type="submit"
                  onClick={onSubmit}
                >
                  <FormattedMessage id={submitTextId || 'Save'} />
                </MainButton>
                {onSecondaryClick && (
                  <MainButton
                    disabled={isLoading}
                    secondary={true}
                    type="button"
                    onClick={onSecondaryClick}
                  >
                    <FormattedMessage id={secondaryTextId || 'Cancel'} />
                  </MainButton>
                )}
              </div>
            )}
          </div>
        </CardWrapper>
      </OverlayWrapper>
      {isLoading && <Loading />}
    </>
  );
};
