import { useIntl } from 'react-intl';
import ActionOverlayButton from './ActionOverlayButton';
import { DataStoreType } from 'models/DataStore';
import { ActionsStoreType } from 'models/ActionsStore';
import { ProjectsStoreType } from 'models/ProjectsStore';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { inject, observer } from 'mobx-react';

function PrototypesButton({
  dataStore,
  actionsStore,
  projectsStore,
  applicationStore
}: {
  dataStore?: DataStoreType;
  actionsStore?: ActionsStoreType;
  projectsStore?: ProjectsStoreType;
  applicationStore?: ApplicationStoreType;
}) {
  const intl = useIntl();
  let disabled = false;
  let intlId: string;
  let intlVars: { count: number; count2: number };

  const organizationId = dataStore!.currentOrganizationId!;
  const projectId = dataStore!.currentProjectId!;
  const hypothesesIds = actionsStore!.selectedIds.Hypothesis;

  return (
    <ActionOverlayButton
      label={intl.formatMessage({ id: 'AI Create prototype' })}
      iconName="ai"
      disabled={dataStore?.project?.reachedPrototypeLimit}
      onClick={async () => {
        if (!dataStore?.project?.briefing.completed) {
          applicationStore!.setAiUnavailable(true);
          return;
        }
        if (disabled) {
          applicationStore!.setFlashMessage(
            intl.formatMessage(
              {
                id: intlId
              },
              intlVars
            ),
            'inlineError'
          );
          return;
        }

        try {
          const response = await projectsStore!.generatePrototypesByAi(
            organizationId,
            projectId,
            hypothesesIds
          );
          if (!response.success) {
            applicationStore!.setFlashMessage(
              intl.formatMessage({ id: 'AI create error' }),
              'error'
            );
          }
        } catch (error: any) {
          applicationStore!.setFlashMessage(
            intl.formatMessage({
              id: 'prototypes search failed error'
            }),
            'error'
          );
        }
      }}
    />
  );
}

export default inject(
  'dataStore',
  'actionsStore',
  'projectsStore',
  'applicationStore'
)(observer(PrototypesButton));
