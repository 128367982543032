import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { Link } from 'react-router-dom';

import OrgCardTop from 'components/CardTop/OrgCardTop';
import CardWrapper from 'components/CardWrapper';
import DestroyButton from 'components/DestroyButton';
import ErrorMessage from 'components/ErrorMessage';
import TagSelectInput from 'components/Inputs/TagSelectInput';
import LabeledIcon from 'components/LabeledIcon';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import LinkRow from 'components/LinkRow';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import StickyBenchmarkButtons from 'components/StickyBenchmarkButtons/StickyBenchmarkButtons';
import TagList from 'components/TagList';
import ActionsOverlayContainer from 'containers/ActionsOverlayContainer';
import { ActionsStoreType } from 'models/ActionsStore';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { BenchmarkModelType } from 'models/BenchmarkModel';
import { BenchmarksStoreType } from 'models/BenchmarksStore';
import { ContentListStoreType } from 'models/ContentListStore';
import { DataStoreType } from 'models/DataStore';
import { HistoryProps, isPush } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';
import { isDefined } from 'utils/misc/is-defined';
import authorIdFromModel from 'utils/store/authorIdFromModel';
import BenchmarksListItem from './BenchmarksListItem';

interface PublicBenchmarksListScreenProps extends WrappedComponentProps {
  showRecommendations?: boolean;
}

interface BenchmarksListScreenState {
  copyToProjectOverlayActive: boolean;
}

interface BenchmarksListScreenProps extends PublicBenchmarksListScreenProps {
  form: FormType;
  contentListStore: ContentListStoreType;
  dataStore: DataStoreType;
  benchmarksStore: BenchmarksStoreType;
  actionsStore: ActionsStoreType;
  applicationStore: ApplicationStoreType;
}

@inject(
  'contentListStore',
  'dataStore',
  'benchmarksStore',
  'actionsStore',
  'applicationStore'
)
@observer
class BenchmarksListScreen extends React.Component<
  BenchmarksListScreenProps & HistoryProps,
  BenchmarksListScreenState
> {
  state = {
    copyToProjectOverlayActive: false
  };

  componentDidMount() {
    if (this.props.showRecommendations) {
      this.loadBenchmarkRecommendations();
    } else {
      this.handleScrollToBenchmark();
      this.loadBenchmarks(!isPush(this.props.history));
    }
  }

  componentDidUpdate(prevProps: BenchmarksListScreenProps): void {
    if (!prevProps.form.loading && this.props.form.loading) {
      this.handleScrollToBenchmark();
    }
  }

  handleScrollToBenchmark() {
    const savedScrollPosition = sessionStorage.getItem(
      'benchmarkScrollPosition'
    );
    if (
      savedScrollPosition &&
      !this.props.form.loading &&
      !this.props.benchmarksStore.isListLoading
    ) {
      const mainElement = document.querySelector('[role="main"]');
      if (mainElement) {
        setTimeout(() => {
          mainElement.scrollTop = parseInt(savedScrollPosition, 10);
        }, 300);
      }
      sessionStorage.removeItem('benchmarkScrollPosition');
    }
  }

  async loadBenchmarks(checkIfAlreadyInStore = false) {
    const { dataStore, benchmarksStore, applicationStore } = this.props;

    if (
      applicationStore.isPreffilingBenchmarks ||
      (checkIfAlreadyInStore && dataStore.benchmarksList)
    ) {
      return;
    }

    await benchmarksStore.getBenchmarks();

    if (
      !dataStore.benchmarksList?.length &&
      dataStore.isProjectEditor &&
      !dataStore.project?.ai_at_work
    ) {
      this.props.history.replace(
        dataStore.contextUri + '/benchmarks/create?empty=yes',
        {
          isRedirect: true
        }
      );
    }
  }

  async loadBenchmarkRecommendations() {
    const { contentListStore, dataStore } = this.props;
    const organizationId = dataStore.currentOrganizationId;
    const projectId = dataStore.currentProjectId;
    if (!organizationId || !projectId) {
      return;
    }
    await contentListStore.getRecommendations(
      ElementType.Benchmark,
      organizationId,
      projectId
    );
  }

  checkChanged(element: BenchmarkModelType, newChecked: boolean) {
    const { dataStore } = this.props;

    const organizationId = dataStore.currentOrganizationId;
    const projectId = dataStore.currentProjectId;

    if (!isDefined(organizationId) || !isDefined(projectId)) {
      return;
    }

    this.props.actionsStore.selectionSet(
      {
        Benchmark: [
          {
            id: element.id,
            organizationId,
            projectId,
            publishState: element.publish_state
          }
        ]
      },
      newChecked
    );
  }

  async deleteBenchmarks(ids: number[]) {
    const { actionsStore, intl, benchmarksStore } = this.props;

    if (!isDefined(ids) || ids.length < 1) {
      return;
    }

    // TODO create nicer confirm?
    if (
      !window.confirm(
        intl.formatMessage(
          { id: 'remove benchmarks confirm' },
          { count: ids.length }
        )
      )
    ) {
      return;
    }

    await benchmarksStore.bulkDeleteBenchmarks(ids);
    actionsStore.selectionClear();
  }

  setFilter(filter: string, value: string, changed: boolean = false) {
    this.props.benchmarksStore.setFilter(filter, value);
  }

  getFilter(filter: string) {
    return this.props.benchmarksStore.filter.get(filter);
  }

  resetFilters() {
    this.setFilter('tag', '');
    this.setFilter('company', '');
    this.setFilter('author', '');
  }

  links() {
    const { dataStore, intl, showRecommendations } = this.props;
    const contextUri = dataStore.contextUri;

    return [
      {
        isLink: showRecommendations,
        to: contextUri + '/benchmarks',
        content: intl.formatMessage({ id: 'Benchmarks' })
      },
      {
        isLink: !showRecommendations,
        to: contextUri + '/benchmarks/inspiration',
        content: intl.formatMessage({ id: 'Recommendations' })
      }
    ];
  }

  renderList() {
    const {
      actionsStore,
      benchmarksStore,
      contentListStore,
      dataStore,
      form,
      intl,
      showRecommendations
    } = this.props;

    if (showRecommendations) {
      if (!contentListStore.hasAnyOf(ElementType.Benchmark)) {
        return this.renderPage(
          <>
            <ContentWrapper>
              <ColumnWrapper gap="3em">
                <LinkRow links={this.links()} />
                <RowWrapper gap="2em">
                  <CardWrapper>
                    <FormattedMessage id={'Recommendations empty'} />
                    <div className="margin-v text-center">
                      <Link
                        className="main-button display-inline-flex"
                        to={dataStore.contextUri}
                      >
                        {intl.formatMessage({
                          id: 'Recommendations to briefing'
                        })}
                      </Link>
                    </div>
                    <img
                      alt={intl.formatMessage({
                        id: 'Recommendations add tags to briefing'
                      })}
                      className="margin-auto-h max-width-50"
                      src="/images/briefing-add-tag.png"
                    />
                  </CardWrapper>
                </RowWrapper>
              </ColumnWrapper>
            </ContentWrapper>
          </>
        );
      }
    } else if (!benchmarksStore.hasAny) {
      return this.renderPage(
        <>
          <ContentWrapper>
            <ColumnWrapper gap="1em">
              <LinkRow links={this.links()} />
              <RowWrapper gap="2em">
                <CardWrapper>
                  <FormattedMessage id="no benchmarks" />
                </CardWrapper>
              </RowWrapper>
            </ColumnWrapper>
          </ContentWrapper>
        </>
      );
    }

    const isOrgAdmin = dataStore.isOrgAdmin;
    const currentUserId = dataStore.currentUserId;
    const list = showRecommendations
      ? contentListStore.benchmarksSlice()
      : benchmarksStore.listWithCurrentFilter;
    const hasFilter =
      this.getFilter('author') ||
      this.getFilter('tag') ||
      this.getFilter('company');
    const checked: number[] = actionsStore.selectedIds.Benchmark;

    return this.renderPage(
      <>
        <ContentWrapper>
          {(benchmarksStore.isListDeleting || form.loading) && <Loading />}

          {!showRecommendations && (
            <TagList>
              <TagSelectInput
                label={intl.formatMessage({ id: 'Sort by' })}
                value={this.getFilter('sort')}
                onChange={(e) => this.setFilter('sort', e.target.value, false)}
              >
                <option value="newest">
                  {intl.formatMessage({ id: 'Newest' })}
                </option>
                <option value="oldest">
                  {intl.formatMessage({ id: 'Oldest' })}
                </option>
                <option value="" disabled={true} />
                <option value="bookmarks_count">
                  {intl.formatMessage({ id: 'Most bookmarks' })}
                </option>
                <option value="comments_count">
                  {intl.formatMessage({ id: 'Most comments' })}
                </option>
                <option value="" disabled={true} />
                <option value="average_target_group_relevance">
                  {intl.formatMessage({ id: 'Best target group relevance' })}
                </option>
                <option value="average_revenue_potential">
                  {intl.formatMessage({ id: 'Best revenue potential' })}
                </option>
                <option value="average_cost_efficiency">
                  {intl.formatMessage({ id: 'Best cost efficiency' })}
                </option>
                <option value="average_differentiation_degree">
                  {intl.formatMessage({ id: 'Best differentiation degree' })}
                </option>
              </TagSelectInput>

              <TagSelectInput
                name="tag"
                value={this.getFilter('tag')}
                onChange={(e) => this.setFilter('tag', e.target.value, true)}
              >
                <option value="">
                  {intl.formatMessage({ id: 'All hashtags' })}
                </option>
                <option value="" disabled={true} />
                {benchmarksStore.tags.map((tag) => (
                  <option value={tag} key={tag}>
                    #{tag}
                  </option>
                ))}
              </TagSelectInput>

              <TagSelectInput
                name="company"
                value={this.getFilter('company')}
                onChange={(e) =>
                  this.setFilter('company', e.target.value, true)
                }
              >
                <option value="">
                  {intl.formatMessage({ id: 'All companies' })}
                </option>
                <option value="" disabled={true} />
                {benchmarksStore.companies.map((company) => (
                  <option value={company} key={company}>
                    {company}
                  </option>
                ))}
              </TagSelectInput>

              <TagSelectInput
                name="author"
                value={this.getFilter('author')}
                onChange={(e) => this.setFilter('author', e.target.value, true)}
              >
                <option value="">
                  {intl.formatMessage({ id: 'All persons' })}
                </option>
                <option value="" disabled={true} />
                {benchmarksStore.authors.map((author) => (
                  <option value={author.id} key={author.id}>
                    {author.name}
                  </option>
                ))}
              </TagSelectInput>
            </TagList>
          )}

          <ColumnWrapper gap="3em">
            <LinkRow links={this.links()} />

            {!showRecommendations && hasFilter && (
              <CardWrapper>
                <DestroyButton
                  label={intl.formatMessage({ id: 'Reset filters' })}
                  iconName="cross"
                  onClick={() => this.resetFilters()}
                />
                <FormattedMessage
                  id="benchmark filter label"
                  values={{ count: list.length }}
                />
              </CardWrapper>
            )}

            {!!showRecommendations && (
              <CardWrapper>
                <RowWrapper gap="1em">
                  <LabeledIcon
                    icon="network"
                    label={intl.formatMessage({ id: 'Network' })}
                    opacity={0.5}
                  />
                  <div
                    style={
                      {
                        color: 'var(--text-color-secondary)'
                      } as React.CSSProperties
                    }
                  >
                    {intl.formatMessage({
                      id: 'Recommendations info benchmarks'
                    })}
                  </div>
                </RowWrapper>
              </CardWrapper>
            )}

            {list.map((element: BenchmarkModelType) => {
              const mayEdit =
                !showRecommendations &&
                (isOrgAdmin || authorIdFromModel(element) === currentUserId);
              const editPath = element.publish_state === 'draft' ? '/edit' : '';

              return (
                <div
                  id={element.id.toString()}
                  key={element.id}
                  onClick={() => {
                    const mainElement = document.querySelector('[role="main"]');
                    if (mainElement) {
                      sessionStorage.setItem(
                        'benchmarkScrollPosition',
                        mainElement.scrollTop.toString()
                      );
                    }
                  }}
                >
                  <BenchmarksListItem
                    benchmark={element}
                    key={element.id}
                    fromList={!showRecommendations}
                    readOnly={showRecommendations}
                    checked={checked.indexOf(element.id) > -1}
                    onChange={(c) => this.checkChanged(element, c)}
                    mayEdit={mayEdit}
                    onEditClick={() =>
                      this.props.history.push(
                        'benchmarks/' + element.id + editPath
                      )
                    }
                    onRemoveClick={() => this.deleteBenchmarks([element.id])}
                    top={
                      showRecommendations && (
                        <OrgCardTop org={element.project?.organization} />
                      )
                    }
                  />
                </div>
              );
            })}

            {!!showRecommendations && (
              <div className="margin-auto-h">
                <Link className="main-button" to="/app/network?type=benchmark">
                  {intl.formatMessage({
                    id: 'More recommendations in network'
                  })}
                </Link>
              </div>
            )}
          </ColumnWrapper>
          {dataStore?.gptRequestCounterList
            ?.filter((item) => item.status == 'in_progress')
            .map((item) => item.request_type == 'benchmarks').length == 0 &&
            actionsStore.selectedCount() == 0 &&
            dataStore.organization?.ai_enabled &&
            !dataStore.project?.reachedBenchmarkLimit && (
              <StickyBenchmarkButtons intl={intl} />
            )}
        </ContentWrapper>

        {!benchmarksStore.isListBusy && (
          <ActionsOverlayContainer
            copyInitiallySelectedOrganizationId={
              showRecommendations ? dataStore.currentOrganizationId : undefined
            }
            copyInitiallySelectedProjectId={
              showRecommendations ? dataStore.currentProjectId : undefined
            }
            showAiBenchmark={true}
            showAiBriefing={true}
            showCopy={true}
            showDelete={
              !showRecommendations &&
              actionsStore.selectedFromProjectIds.length === 1 &&
              actionsStore.selectedFromProjectIds[0] ===
                dataStore.currentProjectId
            }
            showShare={!showRecommendations}
          />
        )}
      </>
    );
  }

  renderLoading() {
    // TODO
    return this.renderPage(<Loading />);
  }

  renderPage(content: any) {
    let style = {};
    if (this.props.applicationStore.isPreffilingBenchmarks) {
      style = { margin: 0, height: '100%', overflow: 'hidden' };
    }

    return (
      <div style={style}>
        <PageHeader
          titleId="Benchmarks"
          logoHeader={
            // tslint:disable-next-line: jsx-wrap-multiline
            <PageLogoHeader
              title={<FormattedMessage id="benchmark list header" />}
              subtitle={<FormattedMessage id="benchmark list subheader" />}
              list={true}
            />
          }
        />
        {content}
      </div>
    );
  }

  render() {
    const { benchmarksStore, contentListStore } = this.props;

    if (benchmarksStore.isListLoading || contentListStore.isListLoading) {
      return this.renderLoading();
    }

    if (benchmarksStore.isListError) {
      return this.renderPage(
        <ErrorMessage
          state={benchmarksStore.listLoadingState}
          onRetry={() => this.loadBenchmarks()}
        />
      );
    }

    return this.renderList();
  }
}

export default injectIntl((props: PublicBenchmarksListScreenProps) => {
  const form = useForm({ sort: 'newest' });
  // @ts-ignore
  return <BenchmarksListScreen {...props} form={form} />;
});
