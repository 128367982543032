import Icon from 'components/Icon';
import { IconSizeType } from 'components/Icon/Icon';
interface IconButtonProps {
  label: string;
  iconName: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  style?: React.CSSProperties;
  iconSize?: IconSizeType;
}

// TODO intl for label
export default function IconButton({
  label,
  iconName,
  onClick,
  style,
  iconSize
}: IconButtonProps) {
  return (
    <button
      type="button"
      className="icon-button"
      aria-label={label}
      onClick={onClick}
      style={style}
    >
      <Icon name={iconName} size={iconSize} />
    </button>
  );
}
